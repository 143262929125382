
export default {
  data() {
    return {
      // elmentList: [
      //   {
      //     pcElementId: 419,
      //     phoneElementId:425,
      //     descList: [
      //       {
      //         descName: "防爆轮式机器人-L4-S",
      //         sort: 1,
      //       },
      //       {
      //         descName: "Ex db IIB T4 Gb",
      //         sort: 2,
      //       },
      //       {
      //         descName: "IP65",
      //         sort: 3,
      //       },
      //       {
      //         descName: "0-1.0m/s",
      //         sort: 4,
      //       },
      //       {
      //         descName: "≤3h",
      //         sort: 5,
      //       },
      //       {
      //         descName: "防爆等级",
      //         sort: 6,
      //       },
      //       {
      //         descName: "防护等级",
      //         sort: 7,
      //       },
      //       {
      //         descName: "行走速度",
      //         sort: 8,
      //       },
      //       {
      //         descName: "工况续航",
      //         sort: 9,
      //       },
      //     ],
      //     type: "image",
      //     sort: 1,
      //   },
      //   {
      //     pcElementId: 420,
      //     phoneElementId: 426,
      //     descList: [
      //       {
      //         descName: "防爆轮式机器人-L4-S",
      //         sort: 1,
      //       },
      //       {
      //         descName:
      //           "采用小型化设计，具有良好的机动性和灵活性，能够深入到狭窄的管道、设备间隙等区域进行巡检，轻松抵达传统巡检设备难以企及的区域，弥补了大型巡检设备的不足。采用超声波传感器实现全方位无死角的防碰撞保护，进一步提升抗腐蚀和防潮功能，能够满足不同作业环境的需求，更加适应复杂多变的海上平台环境。",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 2,
      //   },
      //   {
      //     pcElementId: 421,
      //     phoneElementId:427,
      //     descList: [
      //       {
      //         descName: "应用场景",
      //         sort: 1,
      //       },
      //       {
      //         descName:
      //           "防爆轮式机器人-L4-S可为油气开采业、管道运输业、油煤燃料加工业、燃气产供业、石油化学品制造业、化学纤维制造业、发电业、输配供电业等广大企业厂矿场站提供基于安全生产的智能巡检方案。适用于各类油、气、田、管、罐、线、缆等易燃易爆、有毒有害的室外及室内智能巡检业务场景。",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 3,
      //   },
      //   {
      //     pcElementId: '',
      //     phoneElementId: '',
      //     descList: [
      //       {
      //         descName: "基本功能",
      //         sort: 1,
      //       },
      //       {
      //         descName: "遥控器控制",
      //         sort: 2,
      //       },
      //       {
      //         descName: "急停功能",
      //         sort: 3,
      //       },
      //       {
      //         descName: "防撞功能",
      //         sort: 4,
      //       },
      //       {
      //         descName: "无人自主巡检",
      //         sort: 5,
      //       },
      //       {
      //         descName: "智能避障功能",
      //         sort: 6,
      //       },
      //       {
      //         descName: "补光灯功能",
      //         sort: 7,
      //       },
      //       {
      //         descName: "智能充电功能",
      //         sort: 8,
      //       },
      //       {
      //         descName: "充电保护功能",
      //         sort: 9,
      //       },
      //       {
      //         descName: "网络通讯、远程控制",
      //         sort: 10,
      //       },
      //       {
      //         descName: "定点巡检功能",
      //         sort: 11,
      //       },
      //       {
      //         descName: "环境气体监测",
      //         sort: 12,
      //       },
      //       {
      //         descName: "视频存储监控",
      //         sort: 13,
      //       },
      //       {
      //         descName: "语音播报",
      //         sort: 14,
      //       },
      //       {
      //         descName: "超声波雷达",
      //         sort: 15,
      //       },
      //       {
      //         descName: "远程功能",
      //         sort: 16,
      //       },
      //       {
      //         descName: "可视界面",
      //         sort: 17,
      //       },
      //       {
      //         descName: "离线巡检",
      //         sort: 18,
      //       },
      //       {
      //         descName: "双向语音",
      //         sort: 19,
      //       },
      //     ],
      //     type: "image",
      //     sort: 4,
      //   },
      //   {
      //     pcElementId: 422,
      //     phoneElementId: 427,
      //     descList: [
      //       {
      //         descName: "选配功能",
      //         sort: 1,
      //       },
      //       {
      //         descName: "自动控制充电房",
      //         sort: 2,
      //       },
      //       {
      //         descName: "甲烷激光遥测",
      //         sort: 3,
      //       },
      //       {
      //         descName: "火焰探测功能",
      //         sort: 4,
      //       },
      //       {
      //         descName: "气象环境采集功能",
      //         sort: 5,
      //       },
      //       {
      //         descName: "实时巡检",
      //         sort: 6,
      //       },
      //     ],
      //     type: "image",
      //     sort: 5,
      //   },
     
      //   {
      //     pcElementId: 423,
      //     phoneElementId: 428,
      //     descList: [
      //       {
      //         descName: "防爆轮式机器人-L4-S",
      //         sort: 0,
      //       },
      //       {
      //         descName: "规格参数",
      //         sort: 1,
      //       },
      //       {
      //         descName: "外形尺寸(长×宽×高)",
      //         sort: 2,
      //       },
      //       {
      //         descName: "防爆等级",
      //         sort: 3,
      //       },
      //       {
      //         descName: "驱动方式",
      //         sort: 4,
      //       },
      //       {
      //         descName: "爬坡能力",
      //         sort: 5,
      //       },
      //       {
      //         descName: "越障能力",
      //         sort: 6,
      //       },
      //       {
      //         descName: "主导航方式",
      //         sort: 7,
      //       },
      //       {
      //         descName: "整备质量",
      //         sort: 8,
      //       },
      //       {
      //         descName: "防护等级",
      //         sort: 9,
      //       },
      //       {
      //         descName: "行走速度",
      //         sort: 10,
      //       },
      //       {
      //         descName: "越障能力",
      //         sort: 11,
      //       },
      //       {
      //         descName: "工况续航",
      //         sort: 12,
      //       },
      //       {
      //         descName: "重复导航定位精度",
      //         sort: 13,
      //       },
      //       {
      //         descName: "≤1040mm×650mm×1000mm",
      //         sort: 14,
      //       },
      //       {
      //         descName: "Ex db IIB T4 Gb",
      //         sort: 15,
      //       },
      //       {
      //         descName: "四轮差速转向",
      //         sort: 16,
      //       },
      //       {
      //         descName: "≥20°",
      //         sort: 17,
      //       },
      //       {
      //         descName: "80mm",
      //         sort: 18,
      //       },
      //       {
      //         descName: "SLAM激光导航和SLAM激光定位",
      //         sort: 19,
      //       },
      //       {
      //         descName: "≤275kg",
      //         sort: 20,
      //       },
      //       {
      //         descName: "IP65",
      //         sort: 21,
      //       },
      //       {
      //         descName: "0-1.0m/s，速度可调",
      //         sort: 22,
      //       },
      //       {
      //         descName: "70mm",
      //         sort: 23,
      //       },
      //       {
      //         descName: "≤3h",
      //         sort: 24,
      //       },
      //       {
      //         descName: "≤±3cm",
      //         sort: 25,
      //       },
      //     ],
      //     type: "image",
      //     sort: 6,
      //   },
      //   {
      //     pcElementId: 424,
      //     phoneElementId:429,
      //     descList: [
      //       {
      //         descName: "防爆轮式机器人-L4-S",
      //         sort: 1,
      //       },
      //       {
      //         descName: "玲珑体型，行动随心所欲",
      //         sort: 2,
      //       },
      //     ],
      //     type: "image",
      //     sort: 7,
      //   },
      // ],

      elmentList: [],
      aboutswiper: null,
    };
  },
  mounted() {
    this.$store.dispatch("pageset/getbannerIndex", 0);
    document.documentElement.style.setProperty("--var-primary-color", "#fff");
    //  this.$store.dispatch("pageset/getSourceData", {
    //   createBy: "",
    //   updateBy: "",
    //   elmentList: this.elmentList,
    //   pageId: this.$route.query.pageId, //页面id
    //   pageName: this.$route.path,
    //   path: this.$route.path,
    //   remark: "",
    // });
    // 获取当前页面的配置信息
    this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
        this.elmentList = this.$store.state.pageset.sourceData.elmentList;
    });
  },
  methods: {
    retention() {
      this.$router.push("/reservation");
    },
    openPdf() {
        this.$router.push('/pdfView')
    },

  },
};
